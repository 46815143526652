<uitk-dialog
  [(showModal)]="config.show"
  tkDisableCloseOnEsc="true"
  tkShowHeader="false"
  uitkId="myDialog-4"
  tkTriggerElementId="myDialog_openBtn4"
  tkOwnContent="true"
  [ngClass]="{ 'export-popup': config.showExportPopup, 'loading':config.isLoading }"
>
  <uitk-dialog-header *ngIf="config.showExportPopup">
    <div class="export-msg-heading-wrap">
      <i class="uitk-icon uitk-icon-warning_filled md-icon"></i>
      <div class="export-msg-heading1">{{ getExportHeadingMsg() }}</div>
    </div>
  </uitk-dialog-header>
  <uitk-dialog-content>
    <div cdkTrapFocus="true">
      <div class="confirm-data">
        <div *ngIf="config.showSelectedFilters">
          <span class="filter-review-title">Review selected filters:</span>
          <app-applied-filter-bubble
            [selectedFilters]="selectionDetails.confimrationBubbleData"
          >
          </app-applied-filter-bubble>
          <div class="btn-grp">
            <button *ngIf="showDisplayButton"
              class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
              (click)="displayResults(constants.displayResults)"
            >
              Display Results
            </button>
            <button
              class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs"
              (click)="exportResultsClick()"
              [disabled]="config.disableExport"
            >
              Export Results
            </button>
            <button
              class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
              (click)="closeConfirmPopup(constants.Cancel)"
            >
              Cancel
            </button>
          </div>
        </div>
        <div *ngIf="config.isLoading">
          <div class="loader-wrapper">
            <uitk-loading-indicator
              uitkId="filter-confirm"
              visibleText="Processing..."
              spinnerSize="large"
              [displayOverlay]="false"
              [centerSpinner]='true'
            >
            </uitk-loading-indicator>
          </div>
        </div>
        <div *ngIf="config.isLoadingSinceMoreThan8Secs">
          <p class="filter-review-title">
            This seems to be taking longer than expected …
          </p>
          <div class="btn-grp">
            <button class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" (click)="exportResultsClick()">
              Export Results
            </button>
            <button
              class="uitk-c-button uitk-c-button--ghost uitk-u-margin-right--base uitk-u-margin-bottom--xs"
              (click)="closeConfirmPopup(constants.refineFilters)"
            >
              Refine Filters
            </button>
          </div>
        </div>
        <div *ngIf="config.showExportPopup">
          You will receive an email notification when the export is ready
          to download.
        </div>
      </div>
    </div>
  </uitk-dialog-content>
</uitk-dialog>
