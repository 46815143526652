<div class="mde-expanded-wrapper">
    <div class="row info-wapper">
        <div class="col">
            <div class="row">
                <div class="col-md-12 flex-container"  >
                    <span *ngIf="open" class="collapse-btn" (click)="toggleOpen()">Collapse All</span>
                    <span *ngIf="!open" class="collapse-btn" (click)="toggleOpen()">Expand All</span>
                    <a class="close-icon" (click)="cancel()" > 
                        <i class="uitk-icon uitk-icon-close lg-icon"></i>
                    </a>
                
            </div>
            </div>
            <div class="col">
                
                            <uitk-panels [isAccordion]="false" >
                                <uitk-panel uitkId="mdeMemberInfo" [open]="open" [collapsible]="collapsiblePanel">
                                    <uitk-panel-header-title>
                                        <strong>Member Information</strong>
                                    </uitk-panel-header-title>
                                    <uitk-panel-content>
                                        <div class="row">
                                            <div class="col-md-2"><span class="hdr-cls">Member Name</span></div>
                                            <div class="col-md-2"><span class="hdr-cls">Date of Birth</span></div>
                                            <div class="col-md-2"><span class="hdr-cls">Gender</span></div>
                                        </div>
                                        <div class="row member-info-text">
                                            <div class="col-md-2"><span>{{mdeInfo?.memberInfo.mbrName}}</span></div>
                                            <div class="col-md-2"><span>{{mdeInfo?.memberInfo.mbrDOB}}</span></div>
                                            <div class="col-md-2"><span>{{mdeInfo?.memberInfo.gender}}</span></div>
                                        </div>
                                    </uitk-panel-content>
                                </uitk-panel>
        
                                <uitk-panel uitkId="mdeDiagInfo" [open]="open" [collapsible]="collapsiblePanel">
                                    <uitk-panel-header-title>
                                        <strong >Diagnosis Codes</strong>
                                    </uitk-panel-header-title>
                                    <uitk-panel-content>
                                        <div class="row">
                                            <div class="col-md-2"><span class="hdr-cls">Diagnosis Code</span></div>
                                            <div class="col-md-10"><span class="hdr-cls">Description</span></div>
                                        </div>
                                        <div class="row hdr-btm-cls">
                                            <div class="col"></div>
                                        </div>
                                        <div class="row hdr-data-cls" *ngFor="let diag of mdeInfo?.diagCodes">
                                            <div class="col-md-2"><span>{{diag.dignosisCode}}</span></div>
                                            <div class="col-md-10">
                                                <div class="diag-desc" [title]="diag.description">{{diag.description}}</div>
                                            </div>
                                        </div>
                                    </uitk-panel-content>
                                </uitk-panel>
        
                                <uitk-panel uitkId="mdeErrClmInfo" [open]="open" [collapsible]="collapsiblePanel">
                                    <uitk-panel-header-title>
                                        <strong>Errors in Claim</strong>
                                    </uitk-panel-header-title>
                                    <uitk-panel-content>
                                        <div class="row">
                                            <div class="col-md-2"><span class="hdr-cls">Type</span></div>
                                            <div class="col-md-2"><span class="hdr-cls">Category</span></div>
                                            <div class="col-md-2"><span class="hdr-cls">Loop Name</span></div>
                                            <div class="col-md-2"><span class="hdr-cls">Code</span></div>
                                            <div class="col-md-2"><span class="hdr-cls">Description</span></div>
                                        </div>
                                        <div class="row hdr-btm-cls">
                                            <div class="col"></div>
                                        </div>
                                        <div class="row hdr-data-cls" *ngFor="let err of mdeInfo?.clmErrs">
                                            <div class="col-md-2"><span>{{err.errorType}}</span></div>
                                            <div class="col-md-2"><span>{{err.errorCategory}}</span></div>
                                            <div class="col-md-2 loop-name" [title]="err.loopName" ><span>{{err.loopName}}</span></div>
                                            <div class="col-md-2"><span [title]="err.errorCode">{{err.errorCode}}</span></div>
                                            <div class="col-md-2"><div class="err-desc" [title]="err.errorDescription">{{err.errorDescription}}</div></div>
                                        </div>
                                    </uitk-panel-content>
                                </uitk-panel>
                            </uitk-panels>
                    
            </div>
            <div class="row">
                <div class="col accrdn-btm">
                </div>
            </div>
        </div>
    </div>
    <div class="btns-btm uitk-u-margin--xxs">
        <div>
            <button class="uitk-c-button uitk-c-button--ghost uitk-u-margin--xxs mde-confirmation_buttons" (click)="cancel()">Cancel</button>
        </div>
        <div>
            <button class="uitk-c-button uitk-c-button--default uitk-u-margin--xxs mde-confirmation_buttons" (click)="resumbitClaims()" [disabled]="isMDEResubmittedRecord()">Resubmit MDE Claim</button>
        </div>
    </div>
</div>
<app-error-crr-resubmit [resubmitConfig]="resubmitClmConfig"  (collapsePopUp)="collapsePopUp($event)" >
</app-error-crr-resubmit>


