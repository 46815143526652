import { Component, OnInit, Inject, ViewChild, HostListener } from '@angular/core';

import { ReportsColumnsDialogComponent } from '../../reports/reports.columns.dialog.component';
import { SESSION_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { Constants } from '../../data-models/constants.enum';
import { MdeUtility } from '../../reports/utility/mde.utility';
import { MdeService } from '../../services/tabs/mde.service';
import { UserPrincipal } from '../../services/auth/userPrincipal.model';
import { environment } from '../../../environments/environment';
import { ClaimDetails } from "../../data-models/claimDetails";
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, fromEvent, Subscription } from 'rxjs';
import { SelectItem } from '../../data-models/select.item';
import { SummaryService } from '../../services/tabs/summary.service';
import { ClientService } from '../../services/client.service';
import { SelectionDetails } from '../filter-selection/filter-selection.component';
import { scopeInitData,ErrorPageConfig,ErrorPageConfigModel } from "./claim-errors-details.model";
import { ClaimErrorsHeaderComponent } from '../claim-errors-header/claim-errors-header.component';
import { FileService } from '../../services/file.service';
import { DownloadService } from '../../services/download.service';
import { ClmErrRowExpandComponent } from '../clm-err-row-expand/clm-err-row-expand.component';
import { ErrorCorrectionService } from 'src/app/services/errorCorrectionClm.service';
import { ResubmitClmConfig } from '../error-crr-resubmit/error-crr-resubmit.component';
import { map } from 'rxjs/operators';
import { SessionStorageUtility } from 'src/app/reports/utility/sessionstorage.utility';
import { IUITKPaginationConfig, IUITKPaginationEntriesPerPage, UITKHeadingLevel, UITKPaginationComponent, UITKTableDataSource, UITKTableSortDirective, IUITKPaginationEvent } from '@uitk/angular';



@Component({
  selector: 'app-claim-errors-details',
  templateUrl: './claim-errors-details.component.html',
  styleUrls: ['./claim-errors-details.component.css']
})
export class ClaimErrorsDetailsComponent extends ReportsColumnsDialogComponent implements OnInit {
  @HostListener('window:beforeunload', ['$event']) 
  beforeUnloadHandler(event){
    this.unlockAllSelectedRecords();
  }
  filterSelectionDetails:SelectionDetails= {
    bubbleData:[],
    confimrationBubbleData: []
  };
  
  private readonly errorItemNameMapping = {
    TOTAL_ENCOUNTERS_IN_ERROR: "Total Claims in Error",
    TOTAL_INBOUND_ERRORS: "Total Inbound Errors",
    INBOUND_COMPLIANCE: "Inbound Compliance",
    INBOUND_CONTENT: "Inbound Content",
    TOTAL_CLAIMS_IN_ERROR: "Total Claims in Error",
    CMS_DUPLICATES_CLAIMS:'CMS Duplicate Claims',
    TOTAL_CMS_ERRORS:'Total CMS Errors',
    CMS_999_ERRORS:"CMS 999 Errors",
    CMS_277_ERRORS:"CMS 277 Errors",
    CMS_MAO_002_ERRORS:'CMS MAO-002 Errors'
  }

   errorDetailModel: any = {
    title: 'MDE Submissions',
    enableFiltering: false,
    enableSorting: true,
    enableMultiRowSelect: true,
    clearAllFilters: false,
    fixedHeader: true,
    uniqueAriaLabel: 'fileName',
    pagination: {
      currentPageNumber: 1,
      recordsPerPage: 25,
      displayTopAndBottom: true,
      hideRecordsCount: true,
      recordsPerPageOptions: [25, 50, 100]
    }
  };
  
  records = [];
  pageconfigvalues :any = [Constants.inboundErrorScreenType,Constants.cmsErrorScreenType];
  supplementalFileName:string = "intermediateFileName";
  clonedRecords =[];
  userDetails: UserPrincipal;
  public claimDtlsCriteria: ClaimDetails;
  popupDescription: string = "";
  public scopeData: any = this.deepCopy(scopeInitData);
  public scopeCloneData:any;
  public componentLoading: boolean = true;
  public readonly tkRowComponent = ClmErrRowExpandComponent;
  checkedRows: any = [];
  public msgs: Array<String> = [];
  public isFileLvlErrors: boolean;
  sourceScreen: string;
  errorCountSummary: { name: string; value: string; }[];
  pageConfig: ErrorPageConfigModel;
  UpdateRecordSubscription: Subscription;
  isChecked : boolean;
  public resubmitSelectedRow : any = {};
  public errorCategoryDtls :any = [];
  public resubmitClmConfig: ResubmitClmConfig = {
    loading: false,
    showPopup: false,
    title: '',
    workFlow: '',
    description: ''
  };

  
  paginationDetails : { segNo: number ,isLastPage:boolean,totalRecords :number ,prevSegState : number} ={
    segNo : 0,
    isLastPage : true,
    totalRecords : 0,
    prevSegState :0
  }
  columnSortOrder : any;
  @ViewChild('headerComp', {read: ClaimErrorsHeaderComponent, static: true}) headerComp :  ClaimErrorsHeaderComponent;

  getRecordsSubscription: Subscription;
  isDataLoading: boolean;
  errorTypeList : any = [];
  resubmitKeys={
    [Constants.mdeResubmittedStatus]:true,
    [Constants.failedStatus]:true,
    [Constants.generatedStatus]:true,
    [Constants.errorSubmittedStatus]:true,
    [Constants.claimClosedStatus]:true
  }
  mdeData = [];
  closedMdeClaims = [];
  isAllCheckboxSelected:boolean = false;
  @ViewChild('sortTable') uitkTableSort: UITKTableSortDirective;
  dataSource = new UITKTableDataSource<any>([]);
  paginationConfig: IUITKPaginationConfig =  {
    id: 'pagination-example',
    description: 'Pagination Example',
    currentPageNumber: 1,
    displayingPagesTextHeadingLevel: UITKHeadingLevel.h2,
};

// pagination entries per page configuration
entriesPerPage: IUITKPaginationEntriesPerPage = {
  pageSizeOptions: [{ value: 25, selected: true }, { value: 50 }, { value: 100 }],
};
// UITK Pagination component instance
@ViewChild(UITKPaginationComponent, { static: true }) uitkTablePagination: UITKPaginationComponent;
  constructor(
    @Inject(SESSION_STORAGE) public sessionStorage: WebStorageService,
    @Inject(DOCUMENT) readonly document: Document,
    public dateFormat: DatePipe,
    private readonly mdeService: MdeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly summaryService: SummaryService,
    private readonly clientService: ClientService,
    private readonly fileService: FileService,
    private readonly downloadService: DownloadService,
    private readonly errorCorrectionService : ErrorCorrectionService) {
    super(sessionStorage, dateFormat);
    this.scopeData.viewByDates = [{ id: 0, label: Constants.fileReceivedDate, value: Constants.fileReceivedDate },
      { id: 1, label:Constants.claimDosDate, value:Constants.claimDosDate }];
    this.scopeData.viewByDate = this.scopeData.viewByDates[0];
  }

  ngOnInit() {
    this.userDetails = this.sessionStorage.get(Constants.userPrincipal);
    this.isFileLvlErrors = this.router.url.startsWith("/file");
    this.sourceScreen = this.route.snapshot.params.sourceScreen;
    this.pageConfig = this.deepCopy(ErrorPageConfig[this.sourceScreen]);
    this.sessionStorage.set(Constants.screenType,this.pageConfig.pageType);
    this.claimDtlsCriteria = new ClaimDetails();
    this.columns = MdeUtility.getColumns(this.isFileLvlErrors);
    this.columnSortOrder = MdeUtility.getSortorderList();
    this.visibleColumns = this.getShowHideColumnList(this.columns);  
    this.updateSearchCriteriaFromSession();
    this.loadDropDownData();
    if (this.isFileLvlErrors) {
      this.fetchErrorCountDetails();
    }
    this.subscribeUpdateRecordSubject();
  }

  ngAfterViewInit(){
    this.headerComp && this.headerComp.filterSelection && this.headerComp.filterSelection.prepareSearchInputList();
    this.modifySearchInput();
    this.performClaimsSearch({initialLoad:true});
    this.dataSource.sort = this.uitkTableSort;
     // set UITK Pagination instance to the table dataSource
     this.dataSource.pagination = this.uitkTablePagination;
  }

  
  getShowHideColumnList(columns :any){
    let columnList = this.deepCopy(columns);
    columnList = columnList.filter(function(column){
      return !column.hideAlways 
    });
    
    return columnList;
  }

  modifySearchInput(){
    if(this.isErrorInClaimScreen()){
      this.filterSelectionDetails.apiInput.filterCriteria.forEach( filter => {
        if(filter.key === "ERROR_CODE"){
          filter.operater = "EQUAL";
        }
      })
    }
  }

  updateSearchCriteriaFromSession() {
    this.scopeData.viewByDate =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.viewingBy);
    this.scopeData.selectedClient =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.clientName);
    this.scopeData.timePeriod.period =SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.timePeriod);
    if( this.scopeData.timePeriod.period.value === Constants.customDateRange){
      this.scopeData.timePeriod["fromDate"]  = this.sessionStorage.get(Constants.fromDate);
      this.scopeData.timePeriod["throughDate"] = this.sessionStorage.get(Constants.toDate);
    }
    this.scopeData.sortOrder = { value: 10};
    for(const inputKey in this.pageConfig.apiInput.data){
      this.claimDtlsCriteria[inputKey] = this.pageConfig.apiInput.data[inputKey];
    }
    if(this.pageConfig.apiInput.sessionKeys){
      for(const sessionKey of this.pageConfig.apiInput.sessionKeys){
        this.claimDtlsCriteria[sessionKey] = this.sessionStorage.get(sessionKey);
      }
    }
    if(this.pageConfig.titleSessionKeys){
      for(const titleSessionKey of this.pageConfig.titleSessionKeys){
        this.pageConfig[titleSessionKey] =this.sessionStorage.get(titleSessionKey);
      }
    }
    if(this.pageConfig.exportScreenName){
      this.claimDtlsCriteria["screenName"] = this.pageConfig.exportScreenName;
    }
    if(this.pageConfig.prePopulateScope){
      const scopeKeys = SessionStorageUtility.getSessionStorageData(this.sessionStorage,Constants.scopeDataSessionKeys) || [];
      for(const scopeDatasessionKey of scopeKeys){
        this.scopeData[scopeDatasessionKey] =SessionStorageUtility.getSessionStorageData(this.sessionStorage,scopeDatasessionKey);
      }
    }
    this.filterSelectionDetails.apiInput={sortCriteria:{sortCriteriaID:"10"}};
    
  }

  updateSearchCriteriaIntoSession() {
    this.sessionStorage.set(Constants.clientName, JSON.stringify(this.scopeData.selectedClient));
    this.sessionStorage.set(Constants.timePeriod, JSON.stringify(this.scopeData.timePeriod.period));
    this.sessionStorage.set(Constants.viewingBy, JSON.stringify(this.scopeData.viewByDate));
    if( this.scopeData.timePeriod.period.value === Constants.customDateRange){
      this.sessionStorage.set(Constants.fromDate,this.scopeData.timePeriod.fromDate);
      this.sessionStorage.set(Constants.toDate,this.scopeData.timePeriod.throughDate);
    }
    this.sessionStorage.set(Constants.includeChildData, this.scopeData.includeChildData);
  }

showClaimsResubmitted(resubmittedMedClmSks){
  if(resubmittedMedClmSks.length > 0){
    const resubClaimDetails = this.checkedRows.filter((item) => resubmittedMedClmSks.indexOf(item['medClmSk']) > -1);
    const resubClaimIds = resubClaimDetails.map(eachLockedRecord => eachLockedRecord['clmId']);
    const uniqueResubClaimIds = resubClaimIds.filter((item,pos) => resubClaimIds.indexOf(item) == pos);
    const description = `<b>These claim IDs were resubmitted by another user:</b> <br>
    ${uniqueResubClaimIds.join(", ")} </br>`;
    
    this.popupDescription = this.popupDescription + description;
    
    this.updateCheckRowsAndDynmcTblCompRecords(resubmittedMedClmSks);
    
    this.mdeService.emitUpdateRecord({hideAcknowledgment: true, data:resubClaimDetails,flow:Constants.mdeResubmittedFlow});
  }
}
  selectAllFunction(event){
    if(this.checkedRows.length > 0){
      const checkedRowsCont = this.checkedRows;
      let medClmSkWithPartySk = this.groupByPartySkOfRows(checkedRowsCont);
      this.checkClaimsSubmitted(this.checkedRows).subscribe( resubmittedMedClmSks => {
        this.showClaimsResubmitted(resubmittedMedClmSks);
        
        medClmSkWithPartySk = this.groupByPartySkOfRows(this.checkedRows);
        this.errorCorrectionService.claimsLockedByOtherUser(medClmSkWithPartySk, this.userDetails.preferred_username)
        .subscribe((response) => {
          this.performLockUnlockInMDEScreen(response);
        });
        
      });
    }
  }
  performLockUnlockInMDEScreen(response){
    
    const data = this.transformDataToGetMedClmSks(response);
    if(Object.keys(data).length>0){
      const lockedClaimDetails = this.checkedRows.filter((item) => this.errorCorrectionService.isMedClmSkAvailable(item,data));
      const lockedClaimIds = lockedClaimDetails.map(eachLockedRecord => eachLockedRecord['clmId']);
      const uniqueLockedClaimIds = lockedClaimIds.filter((item,pos) => lockedClaimIds.indexOf(item) == pos);
      const description = `<b>These claim IDs are locked for editing by another user:</b> </br>
      ${uniqueLockedClaimIds.join()}`;
      
      this.popupDescription = this.popupDescription + '</br>' + description;
      
      this.checkedRows = this.checkedRows.filter((record)=>{
        const lockUnlockMedClmSks = data[record['partySk']];
        if(lockUnlockMedClmSks && lockUnlockMedClmSks.indexOf(String(record.medClmSk))>=0){
          record.isChecked = false;
          return false;  
        }else{
          return true;  
        }
      })
      this.checkedRows = this.checkedRows;
      
      lockedClaimDetails.forEach(lockedRecord => {
        this.mdeService.emitUpdateRecord({ data:lockedRecord, flow: Constants.claimLockedFlow, status: Constants.claimLocked,key:"isLocked"});
      });
    }
    if(this.popupDescription !== ""){
      this.showLockClaimPopup(this.popupDescription, Constants.someClmIdNotAvlblConst);
      this.popupDescription = "";
    }
    const medClmSksWithPartySkForLocking = this.groupByPartySkOfRows(this.checkedRows);
  
    if(Object.keys(medClmSksWithPartySkForLocking).length>0){
      this.errorCorrectionService.lockUnlockClaims(medClmSksWithPartySkForLocking, this.userDetails.preferred_username, 
        Constants.claimLocked, this.userDetails.sessionIdGenerated).subscribe((data) => {
          console.log("called lock unlock claims to lock the record");
      });
    }  
  
  }

  transformDataToGetMedClmSks(response){
    const lockUnlockDetails={};
    for(const lockUnlockDetail of response){
      if(lockUnlockDetail["partySk"] && lockUnlockDetail["claimsTableInfo"]){
        const medClmSks=Object.keys(lockUnlockDetail["claimsTableInfo"]).filter(eachMedClmSk => lockUnlockDetail["claimsTableInfo"][eachMedClmSk] === "Y")
        if(medClmSks.length>0){
          lockUnlockDetails[lockUnlockDetail["partySk"]]=medClmSks;
        }
      }
    }
    return lockUnlockDetails;
  }

  
  onCancel() {
    this.unlockAllSelectedRecords();
    this.resetCheckboxSelection();
    this.dataSource.data.forEach((element:any)=>{element.isChecked = false});
    this.validateAllCheckboxStatus(this.document);
    
  }
  unlockAllSelectedRecords(unlockRows:any = this.sessionStorage.get(Constants.sessionLockedRecords) || []) {
    if(Array.isArray(unlockRows) && unlockRows.length > 0){
     const medClmSksWithPartySkForUnlocking = this.groupByPartySkOfRows(unlockRows);
     this.errorCorrectionService.lockUnlockClaims(medClmSksWithPartySkForUnlocking, this.userDetails.preferred_username, 
     Constants.claimUnlocked, this.userDetails.sessionIdGenerated).subscribe((response) => {
       const data = this.transformDataToGetMedClmSks(response);
       if(Object.keys(data).length>0){
        this.checkedRows = this.checkedRows.filter((record)=>{
          const lockUnlockMedClmSks = data[record['partySk']];
          if(lockUnlockMedClmSks && lockUnlockMedClmSks.indexOf(String(record.medClmSk))>=0){
            record.isChecked = false;
            return false;  
          }else{
            return true;  
          }
        })
        this.checkedRows = this.checkedRows;
       }
     });
   }
  }
  onSubmit() {
    this.mdeData = [];
    const userPrincipal: UserPrincipal = JSON.parse(sessionStorage.getItem('userPrincipal'));
    this.checkedRows.forEach(row => {
      this.mdeData.push({
        "clmType": row["clmType"],
        "medClmSk": Number(row["medClmSk"]),
        "fileKey": Number(row["fileKey"]),
        "partySk": Number(row["partySk"]),
        "fileName": row["fileName"],
        "fileReceivedDate": row["fileReceivedDate"],
        "clmId": row["clmId"],
        "dosFrom": row["dosFrom"],
        "dosThrough": row["dosThrough"],
        "mbi": row["mbi"],
        "billingPrvNpi": row["billingPrvNpi"],
        "status": "IN_PROGRESS",
        "userId": userPrincipal.preferred_username,
        "errType": row["errType"],
        "clmSvcMonth": row["clmSvcMonth"],
        "clmSvcDtJulian": row["clmSvcDtJulian"],
        "rowIndex": row["rowIndex"],
        "expanded": row["expanded"]
      });
    });
    
    const medclmSklist =[];
     this.mdeData.forEach(item => {
      medclmSklist.push(item['medClmSk']);
     });
    
    this.errorCorrectionService.isClaimClosed(medclmSklist,true).subscribe((data: any) => {
      if (data) {
       
        this.updateClosedClaimStatus(data);

        if(this.closedMdeClaims.length !== 0){
            this.mdeService.emitUpdateRecord({ data:this.closedMdeClaims, flow: Constants.mdeResubmittedFlow, status: Constants.claimClosedStatus,
              hideAcknowledgment : true });
            this.resetCheckboxSelection();
            this.unlockAllSelectedRecords(this.closedMdeClaims);
        }
        else if(this.closedMdeClaims.length === 0 && this.mdeData.length >=1){ 
            this.submitUnclosedMdeClms(); 
        }        
     }
            
    }, err => {
    });
    
  }

  filterClosedUnClosedMdeClms(clmDataResp){
    if(clmDataResp.closedMedClmSk){
    this.closedMdeClaims = this.mdeData.filter((item) =>{
          return  clmDataResp.closedMedClmSk.indexOf(item['medClmSk']) !== -1 } )
          
    this.mdeData = this.mdeData.filter((item) =>{
        return  clmDataResp.closedMedClmSk.indexOf(item['medClmSk']) === -1 } )
    }
    
  }

  updateClosedClaimStatus(clmDataResp){
    
    this.filterClosedUnClosedMdeClms(clmDataResp);

    if(clmDataResp.success === "true"){
      const updateClosedClaims = [];

      this.closedMdeClaims.forEach(element => {
        updateClosedClaims.push({medClmSk:element['medClmSk'],orgFileKey:element['fileKey'],partySK:element['partySk']});
      })

      this.errorCorrectionService.updateClaimStatus(updateClosedClaims).subscribe(resp =>{
          this.resubmitClmConfig = {
            loading: false,
            showPopup: clmDataResp.closedMedClmSkCnt > 0 ? true : false,
            title: clmDataResp.closedMedClmSkCnt > 1 ? 'The following claims were already closed' :'This claim was closed',
            workFlow: this.mdeData.length > 0 ? "MdeResubmit" : Constants.MDEClosed,
            description: clmDataResp.closedMedClmSk ? this.errorCorrectionService.getClosedMdeClaims(this.closedMdeClaims) : ""
          };
    }, err => {
          })
    }
    
  }

  submitUnclosedMdeClms(){
    this.mdeService.submitMdeData(this.mdeData).subscribe(data => {
        this.unlockAllSelectedRecords();
        this.mdeService.emitUpdateRecord({data:this.mdeData,flow:Constants.mdeResubmittedFlow});
      });
      this.resetCheckboxSelection();
      this.collapseExpandedMDERecords(); 
      setTimeout(() => {
        this.msgs = [];
      }, 3000);
  }
  
  resetCheckboxSelection(){
    this.checkedRows = [];
  }
  
  performClaimsSearch(action:{isLoadMore?:boolean,filter?:boolean,initialLoad?:boolean} = {}) {
    this.unlockAllSelectedRecords();
    if(!action.initialLoad){
      this.calculateTime();
    }
    this.updateSearchCriteriaIntoSession();
    const partySK = this.scopeData.selectedClient.value;
    const isChildDataIncluded =  this.scopeData.selectedClient.includeChildData;
    const clmDtCriteriaId  = this.scopeData.viewByDate.id;
    const timeCriteriaId = this.scopeData.timePeriod.period.id;
    const segNo = this.paginationDetails.segNo;
    this.getRecordsSubscription = forkJoin(
      {
        data:this.mdeService.getClaimSearchResults(
          partySK, 
          isChildDataIncluded,
          clmDtCriteriaId, 
          timeCriteriaId, 
          segNo, 
          this.claimDtlsCriteria,
          this.filterSelectionDetails.apiInput),
        count :this.mdeService.getClaimSearchTotalCount(
          partySK, 
          isChildDataIncluded,
          clmDtCriteriaId, 
          timeCriteriaId, 
          segNo, 
          this.claimDtlsCriteria,
          this.filterSelectionDetails.apiInput)
      }).subscribe((result: any) =>{
        if(!action.initialLoad){
          this.isDataLoading = false; 
          this.fileService.emitFilterRecodAPIStatusSubject({received : true});
        }
        if(result.data){ 
          this.errorDetailModel["pagination"].currentPageNumber = 1;
          this.errorDetailModel["filterCondition"] = {
            columnSorting: this.columnSortOrder[this.filterSelectionDetails.apiInput.sortCriteria.sortCriteriaID || "10"]
          }
          const records = this.modifyFileLoadRecords(result.data['claimDetails']);
          this.records = action.isLoadMore ? [...this.records,...records] : records;
          this.dataSource.data = this.records;
          if(action.isLoadMore){
            this.scrollToTop();
          }else if(action.filter){
            this.updateBubbleData(this.filterSelectionDetails);
            this.setScopeSelection();
          }
          this.paginationDetails.totalRecords = result.count.totalRecords;
          this.paginationDetails.isLastPage = result.count.lastpage;
          this.paginationDetails.prevSegState =  this.paginationDetails.segNo ;
        }
        this.componentLoading = false;
      },error=>{
        console.log(error);
        this.componentLoading = false;
      })
  }
  modifyFileLoadRecords(records){
    records.map((item, index) =>{
      if(item.isMDEFlg){
        item.isMDEUIFlg = true;
      }
      item.isEditFlg = ( item.errType !== "COMPLIANCE" && item.isEditable);
      item.isExpandableRow = item.isMDEUIFlg || item.isEditFlg;
      item["rowIndex"] = index + (this.paginationDetails.segNo * Number(Constants.maxRecordsPerSeg));
      return item;
    });
    return records;
  }

  scrollToTop(){
    window.scroll({
      top:100,
      left:100,
      behavior: "auto"
    }); 
  }

  fetchErrorCountDetails() {
    this.mdeService.getErrorCountSummaryDetails(
      this.scopeData,
      this.paginationDetails.segNo, 
      this.claimDtlsCriteria,
      ).subscribe(data => {
      for(const errorItem of data){
        errorItem["displayName"] = this.errorItemNameMapping[errorItem["name"]];
      }
      this.pageConfig.errorCountSummary = data;
    })
  }
  
  isMDEScreen(){
    return this.sourceScreen === Constants.mdeScreen;
  }

  isErrorInClaimScreen(){
    return this.sessionStorage.get(Constants.screenType) === Constants.errorDetailsScreenType;
  }

  changeEventCallback(event){
    if(event.action === Constants.clientName){
      this.scopeData.selectedClient = event.model;
      this.performClaimsSearch();
    } else if(event.action === Constants.timePeriod){
      this.scopeData.timePeriod.period = event.model;
      this.performClaimsSearch();
    } else if(event.action === Constants.viewByDate){
      this.updateTimePeriod();
      this.getFilteredErrorTypeList();
    } else if(event.action === Constants.displayResults){
      this.paginationDetails.segNo = 0;
      this.performClaimsSearch({filter:true});
    } else if(event.action === Constants.resetFilter){
      this.resetScopeSelection();
    }else if(event.action === Constants.export){
      this.exportResult();
    }else if(event.action === Constants.loadMore){
      this.componentLoading = true;
      this.performClaimsSearch({isLoadMore:true,initialLoad:true});
    }
  }


  exportResult(exportReporting:boolean = false){
    const selectedMappedRows = [];
    if( exportReporting && this.checkedRows.length > 0){
      this.getExportSelectedRecords(selectedMappedRows);
    }
    this.claimDtlsCriteria["claimRecords"] = selectedMappedRows;
    if(!exportReporting){
      this.generateSearchInputList();
    }
    const segNo = this.paginationDetails.segNo;
    this.fileService.emitFilterRecodAPIStatusSubject({loading : true});
    const availableRecords =  (exportReporting)
      ? ((this.checkedRows.length > 0)? this.checkedRows.length : this.getAvailableRecordsCount())
      : 0;
    
    const {bubbleData,filterSelectionAPIInput} = this.getExportFilterAPIInput(exportReporting,this.filterSelectionDetails);
    const filterLabel = this.getExportFilterLabel(bubbleData);
    filterSelectionAPIInput.isChildDataIncluded = this.scopeData.selectedClient.includeChildData;
    this.downloadService.exportData( 
      this.userDetails,
      segNo, 
      this.claimDtlsCriteria,
      filterSelectionAPIInput,
      availableRecords,
      filterLabel).subscribe(data => {
        this.fileService.emitFilterRecodAPIStatusSubject(
          {export : true,selectedRecords:availableRecords});
        if(exportReporting){
         this.resetCheckboxSelection();
        }
    },err=>{
      this.fileService.emitFilterRecodAPIStatusSubject({received : true});
    })
    if(!exportReporting){
      this.resetScopeSelection();
    }
  }

  getExportSelectedRecords(selectedMappedRows){
    for(const index in this.checkedRows){
      const checkedRow = this.checkedRows[index];
      const mappedRow = {}
      for(const colIndex in this.visibleColumns){
        const column = this.visibleColumns[colIndex];
        if(column["hideColumn"] == false && column["label"]!=""){
          mappedRow[column["label"]] = checkedRow[column["id"]];
        }
      }
      selectedMappedRows.push(mappedRow);
    }
  }
  
  updateTimePeriod(){
    this.summaryService.getTimePeriod(this.scopeData.viewByDate.id).subscribe((data)=>{
      this.scopeData.timePeriods = data;
      if(this.scopeData.viewByDate.id === 0){
        this.scopeData.timePeriod.period = data[1];
        }else if(this.scopeData.viewByDate.id === 1){
          this.scopeData.timePeriod.period = data[this.scopeData.timePeriods.length -2];
        }
        this.syncDropdownSelection();
    })
  }

  loadDropDownData(){
    const pageType = this.sessionStorage.get(Constants.screenType);
    forkJoin(
      {
        client:this.clientService.getClients(this.userDetails.preferred_username),
        timePeriod :this.summaryService.getTimePeriod(this.scopeData.viewByDate.id),
        errorCategory :this.mdeService.fetchErrorCategory(),
        claimType :this.mdeService.fetchClaimType(),
        errorType :this.mdeService.fetchErrorType(pageType),
        dateOfServices :this.summaryService.getTimePeriod(0),
        sortbylist:this.summaryService.getPrioritizeOptionsForFileLoad(pageType)
      }
    ).subscribe(data =>{
      this.scopeData.timePeriods = data.timePeriod;
      this.scopeData.clientNames = [];
      for (let index = 0; index < data.client.length; index++) {
        const element = data.client[index];
        const selectItem: SelectItem = new SelectItem();
        selectItem.id = element["dClntSk"];
        selectItem.label = element["clntNm"];
        selectItem.value = element["prtySk"];
        selectItem.includeChildData = element["includeChildData"];
        this.scopeData.clientNames.push(selectItem);
      }
      this.scopeData.claimTypes = this.updateDropDownList(data.claimType);
      this.errorTypeList = this.updateDropDownList(data.errorType);
      this.getFilteredErrorTypeList();
      this.scopeData.errorCategories = this.updateDropDownList(data.errorCategory);
      this.scopeData.sortbylist = this.updateDropDownList(data.sortbylist);
      this.scopeData.sortOrder = this.scopeData.sortbylist[0];
      this.scopeData.dateOfServices = data.dateOfServices;
      this.scopeData.mdeFilters = [{label:"Yes",value:"Yes",id:"0"},{label:"No",value:"No",id:"1"}];
      this.scopeData.canEdits = [{label:"Yes",value:"Y",id:"0"},{label:"No",value:"N",id:"1"}];
      this.loadInitialBubbleData();
      this.syncDropdownSelection();
      this.setScopeSelection();
    },error =>{
      console.log(error);
    });
  }

  updateDropDownList(list){
    for(const idx in list){
      list[idx]["label"]= list[idx].name;
      list[idx]["id"] = idx;
    }
    return list;
  }

  getFilteredErrorTypeList() {
    const isFileRecieved: boolean = this.scopeData.viewByDate.id === 0;
    this.scopeData.errorTypes = this.errorTypeList.filter(function (item) {
      return isFileRecieved || item['name'] !== Constants.inboundCompliance;
    })

    if (!isFileRecieved && this.scopeData.errorType && 
      this.scopeData.errorType.name === Constants.inboundCompliance) {
      this.scopeData.errorType = null;
    }

  }

  resetScopeSelection(){
    this.scopeData = this.deepCopy(this.scopeCloneData);
    this.syncDropdownSelection();
  }

  loadInitialBubbleData(){
    this.generateSearchInputList();
    this.modifySearchInput();
    this.updateBubbleData(this.filterSelectionDetails);
  }

  generateSearchInputList(){
    this.headerComp && this.headerComp.filterSelection && this.headerComp.filterSelection.prepareSearchInputList();
  }

  subscribeUpdateRecordSubject(){
    this.UpdateRecordSubscription = this.mdeService.subscribeUpdateRecord().subscribe((event: any)=>{
      const recordKey = event.key || Constants.mdeResubmittedKey;
      if(event.flow === Constants.mdeResubmittedFlow){
        this.updateMDESubmittedRecords(event.data,event.status);
      }else {
        this.records.forEach((record)=>{
          if(record.medClmSk === event.data.medClmSk){
            record[recordKey] = event.status; 
            if(recordKey === "isLocked"){
              record["lockedByUserId"] = ""; 
            }
          }
        })
      } 
      if(!event.hideAcknowledgment){
        this.showResubmitSuccessMsg(event);
      }
      
      if(event.flow !== Constants.errorCorrectedFlow){
       const errorRecords = (Array.isArray(event.data) ? event.data : [event.data]).map(x => x['medClmSk']);
        this.updateCheckRowsAndDynmcTblCompRecords(errorRecords);
      }
    })
  }
  showResubmitSuccessMsg(event){
    if(event.flow === Constants.errorSubmittedFlow || event.flow === Constants.mdeResubmittedFlow){
      this.msgs = [];
      if(event.flow === Constants.errorSubmittedFlow){
        this.msgs.push(`Claim # ${event.data.clmId} is updated and will be resubmitted for validation`);
      }else{
        for(const idx in event.data){
          this.msgs.push(`Claim # ${event.data[idx].clmId} is updated and will be resubmitted for validation`);
        }
      }
      setTimeout(() => {
        this.msgs = [];
      }, 3000);
     } 
  }

  updateMDESubmittedRecords(mdeSubmittedRecords,status = Constants.mdeResubmittedStatus){
    this.records.forEach((record)=>{
      for(const key in mdeSubmittedRecords){
        if(record.medClmSk == mdeSubmittedRecords[key].medClmSk && record.fileKey == mdeSubmittedRecords[key].fileKey){
          record[Constants.mdeResubmittedKey] = status;
          break;
        }
      }
    })
  }

  ngOnDestroy(){
    this.unlockAllSelectedRecords();
    this.UpdateRecordSubscription && this.UpdateRecordSubscription.unsubscribe();
  }

  calculateTime(){
    this.isDataLoading = true;
    setTimeout(() => {
      if(this.isDataLoading){
        this.paginationDetails.segNo =  this.paginationDetails.prevSegState ;
        this.getRecordsSubscription.unsubscribe();
        this.isDataLoading = false;
        this.fileService.emitFilterRecodAPIStatusSubject({longerThan8Secs : true});
      }
    }, 8000);
  }
  
  getUuid(r){
    return (r.medClmSk ? r.medClmSk :  `${r.fileKey}_${r.clmId}`) +""+ r.rowIndex;
  }

  getPaginationDetails(){
    const count = this.getAvailableRecordsCount();
    return "Showing "+ count + (count > 1 ? ' records' : ' record');
  }

  getAvailableRecordsCount(){
    return this.paginationDetails.totalRecords > (this.paginationDetails.segNo +1) * Number(Constants.maxRecordsPerSeg) ? (this.paginationDetails.segNo +1) * Number(Constants.maxRecordsPerSeg) : this.paginationDetails.totalRecords;
  }

  setScopeSelection(){
    this.scopeCloneData = this.deepCopy(this.scopeData);
  }


  exportRecordsClick(){
    this.exportResult(true);
  }
  onResubmitClaimClick(event,item){ 
    this.resubmitClmConfig.loading = true;
    this.resubmitClmConfig.showPopup = true;
    this.resubmitSelectedRow = item;
    this.fetchErrorCategoryDetail(this.resubmitSelectedRow.medClmSk, this.scopeData.viewByDate.id);
  }

  fetchErrorCategoryDetail(medClmSk,clmDtCriteriaId){
   this.errorCorrectionService.getErrorCategoryDetails(medClmSk,clmDtCriteriaId).subscribe(data =>{
      this.errorCategoryDtls = data;
      this.resubmitClmConfig = {
        loading: false,
        showPopup: true,
        title: 'Alert',
        workFlow: 'resubmitted',
        description: ''
        }; 
    },error =>{
      console.log(error);
    });
    
 }

 collapsePopUp(event){
  if(event.flow === "resubmitted"){
    this.errorCorrectionService.lockUnlockClaim(this.resubmitSelectedRow.medClmSk, this.resubmitSelectedRow.partySk, 
    this.userDetails.preferred_username, Constants.claimUnlocked, this.userDetails.sessionIdGenerated).subscribe(()=>{});
  }else if(event.flow === 'MdeResubmit'){
    this.submitUnclosedMdeClms();
  }else{
  this.componentLoading=false;
  this.resubmitClmConfig.showPopup=false;
  }

  if(event.type === 'closed' && event.flow === Constants.MDEClosed){
    this.collapseExpandedMDERecords();
  }
}

onLockIconClick(event,item){
  event.preventDefault();
  event.stopPropagation();
  this.checkClaimLockedStatus(item.medClmSk, item.partySk).subscribe(isLocked => {
    if (!isLocked) {
      this.mdeService.emitUpdateRecord({ data:item, flow: Constants.claimClosedFlow, status: Constants.claimUnlocked,key:"isLocked"})
    }
  })
}
  
  onExpandIconClick(event, expandRowType, item) {
    event.preventDefault();
    event.stopPropagation();
    if (this.isIconDisable(item)) {
      return
    }
    this.checkClaimsSubmitted([item]).subscribe( resubmittedMedClmSks => {
      if(resubmittedMedClmSks.length > 0){
        this.showRecordAlreadySubmitted(item, resubmittedMedClmSks);
      
      }else{
        this.checkClosedClaimOnExpand(event, expandRowType, item);
      }
    });
  }


  checkClosedClaimOnExpand(event, expandRowType, item){
    this.errorCorrectionService.isClaimClosed(item.medClmSk)
        .subscribe((data: any) => {
          if (data.success === 'true') {
            const clmdata = [{medClmSk : item.medClmSk,orgFileKey: item.fileKey,partySK: item.partySk}]
            this.errorCorrectionService.updateClaimStatus(clmdata)
            .subscribe((res: any) => {
              this.resubmitClmConfig = {
                loading: false,
                showPopup: true,
                title: 'This claim was closed',
                workFlow: 'closed',
                description: this.errorCorrectionService.getClosedMdeClaims([item])
                };
            }, err => {
            });
            this.checkedRows = this.checkedRows.filter((record)=>{
              if(record.medClmSk !== item.medClmSk){
                  return true;
              }else{
                record.isChecked = false;
                return false; 
              }
            })
            this.checkedRows = this.checkedRows;
            this.mdeService.emitUpdateRecord({ data:item, flow: Constants.claimClosedFlow, status: Constants.claimClosedStatus });    
          } else{
            this.expandClaim(event, expandRowType,item);
          }
          
        }, err => {
        });
  }
  expandClaim(event, expandRowType,item){
    this.checkClaimLockedStatus(item.medClmSk, item.partySk).subscribe(isLocked => {
      if (isLocked) {
        this.mdeService.emitUpdateRecord({ data:item, flow: Constants.claimClosedFlow, status: Constants.claimLocked,key:"isLocked"})
        return
      }
      this.errorCorrectionService.lockUnlockClaim(item.medClmSk, item.partySk, 
        this.userDetails.preferred_username, Constants.claimLocked, this.userDetails.sessionIdGenerated).subscribe(
          (response: any) => {
            if (response && response[0] && response[0].claimsTableInfo[item.medClmSk] === "Y") {
              if(expandRowType  === "resubmit"){
                this.onResubmitClaimClick(event,item);
              }else{
              this.expandErrorDetails(item,expandRowType);
              }
            }
      })
    })  
}

  expandErrorDetails(item,expandRowType){
    const uuid = this.getUuid(item)
    const rowElement: any = this.document.getElementById(uuid);
    if ((!item.expanded || item.rowExpandType === expandRowType) && rowElement) {
      const children = rowElement.parentElement.children;
      let mdeIcon;
      for (const el of children) {
        if (el && el.innerHTML && el.innerHTML.includes("uitk-c-table__expandable-row-button")) {
          mdeIcon = el.getElementsByTagName("button");
          break;
        }
      }
      if (mdeIcon && mdeIcon.length > 0) {
        mdeIcon[0].click();
        item.expanded = true;
      }
    }
    item.rowExpandType = expandRowType
  }

  checkClaimLockedStatus(medClmSk,partySk) {
    return this.errorCorrectionService.isClaimLocked(medClmSk, partySk, this.userDetails.preferred_username).pipe( map((data) => {
      const isLocked = (data && data[0] && data[0].claimsTableInfo && data[0].claimsTableInfo[medClmSk]==="Y");
      if(isLocked){
        this.showLockClaimPopup();
      }
      return isLocked;
    }))
  }
  showLockClaimPopup(desc: string= Constants.singleClaimLockedDesc, title: string = Constants.alert){
    this.resubmitClmConfig  = {
      loading: false,
      showPopup: true,
      title: title,
      workFlow: 'lockMsg',
      description: desc
    }
    
  }
  isRecordSubmitted(item){
    return item && this.resubmitKeys[item[Constants.mdeResubmittedKey]];
  }
  isIconDisable(record){
    return record.expanded || this.isRecordSubmitted(record) || this.isRecordLocked(record);
  }
  isQuickResubmitClaim(record,col){
    return !this.isRecordLocked(record) && record[col.id] === Constants.errorCorrectedStatus;
  }

  isRecordLocked(record){
    return record && record.isLocked === Constants.claimLocked && record.lockedByUserId !== this.userDetails.preferred_username;
  }
  onRowCheckboxClick(event,item){
    this.selectRow(item,event);
    this.isMDEScreen() && this.registerCheckboxSelectionEvent(event,item);
  }

  registerCheckboxSelectionEvent(event,item){
    this.resubmitClmConfig.loading = true;
    this.resubmitClmConfig.showPopup = true;
    if(event.target.checked){
      this.validateCheckboxSelection(item,event)
    }else {
      if(!item.expanded){
        this.errorCorrectionService.lockUnlockClaim(item.medClmSk, item.partySk, this.userDetails.preferred_username, Constants.claimUnlocked, this.userDetails.sessionIdGenerated)
        .subscribe((response: { success: string }) => {
          this.resubmitClmConfig.showPopup = false;
        })
      }else{
        this.resubmitClmConfig.showPopup = false;
      }
    }
    
  }
  registerSelectAllCheckBoxSelectionEvent(event) {
    if(this.isMDEScreen()){
      const currentPageCheckedRows = Array.from(document.querySelectorAll('td input')).filter(item => item['checked']);
      const currentPageNonDisabledRecords = Array.from(document.querySelectorAll('td input')).filter(item => !item['disabled']);
      if(event.target.checked){
        if(!(currentPageCheckedRows.length === currentPageNonDisabledRecords.length)){
          this.performMDESelectAll();
        }
      }else{
        const currentPageCheckedRows = Array.from(document.querySelectorAll('td input')).filter(item => item['checked']);
        const currentPageCheckedRecords = this.records.filter((item) => 
        !item.expanded && currentPageCheckedRows.map(x => x.id).indexOf(String(item.rowIndex)) > -1);
        this.unlockAllSelectedRecords(currentPageCheckedRecords);
      }
    }else{
      this.selectAllEvent(event,this.dataSource,this.document);
    }
  }
  performMDESelectAll(){
    const currentPageInputElements = Array.from(this.document.querySelectorAll("td input.row-checkbox"));
    const currentPageRecords = this.records.filter(item => 
      currentPageInputElements.map(x => x.id)
      .indexOf(String(item.rowIndex)) > -1);
    
    const rowsSelected = currentPageRecords.filter((item)=>{
      if(!(this.isRecordSubmitted(item) || this.isRecordLocked(item) || item.isChecked)){
          item.isChecked = true;
          return true;
      }else{
        item.isChecked = false;
        return false; 
      }
    })
    this.checkedRows = [...this.checkedRows,...rowsSelected];
    this.selectAllFunction(rowsSelected);
  }
  showRecordAlreadySubmitted(item, resubmittedMedClmSks){
    const description = `The claim has been edited and resubmitted by another user`;
    
    this.showLockClaimPopup(description);
    this.updateCheckRowsAndDynmcTblCompRecords(resubmittedMedClmSks);
    
    this.mdeService.emitUpdateRecord({hideAcknowledgment:true,data:[item],flow:Constants.mdeResubmittedFlow});
  
  }
  validateCheckboxSelection(item,event){
    this.checkClaimsSubmitted([item]).subscribe( resubmittedMedClmSks => {
      if(resubmittedMedClmSks.length > 0){
        this.showRecordAlreadySubmitted(item,resubmittedMedClmSks);
      }else{
        this.checkClaimLockedStatus(item.medClmSk, item.partySk).subscribe(isLocked => {
          if (isLocked) {
            this.checkedRows = this.checkedRows.filter((record)=>{
              if(record.medClmSk !== item.medClmSk){
                  return true;
              }else{
                record.isChecked = false;
                return false; 
              }
            })
            this.checkedRows = this.checkedRows;
            event.target.checked = false;
            this.mdeService.emitUpdateRecord({ data:item, flow: Constants.claimClosedFlow, status: Constants.claimLocked,key:"isLocked"});
            
          }else{
            this.errorCorrectionService.lockUnlockClaim(
              item.medClmSk, item.partySk, this.userDetails.preferred_username, Constants.claimLocked, this.userDetails.sessionIdGenerated)
              .subscribe((response: any) => {
              this.resubmitClmConfig.showPopup = false;
            })
          }
        }
        )
      }
    })
  }

  checkClaimsSubmitted(errorRecords){
    errorRecords = this.createResubmitDataBody(errorRecords);
    return this.errorCorrectionService.errorCorrectionResubmitStatus(errorRecords)
    .pipe( map((response) => {
      return this.fetchResubmittedMedClmSks(response);
    }));
  }

  fetchResubmittedMedClmSks(response: any){
    if(Array.isArray(response)){
      return response.filter(eachData => this.resubmitKeys[eachData['status']]).map(x => x['medClmSk']);
    }else{
      return [];
    }
  }
  createResubmitDataBody(errorRecords){
    const data = [];
    for(const item of errorRecords){
      const eachRec =  {
            "medClmSk": item.medClmSk, 
            "partySK": item.partySk,
            "fileKey": item.fileKey,
            "clmDtCriteriaId": this.scopeData.viewByDate.id
        };
      data.push(eachRec);
    }
    return data;
  }

  groupByPartySkOfRows(rows){
    const medClmSksWithPartySk = {};
    for(const eachRecord of rows) {
      medClmSksWithPartySk[eachRecord.partySk]= medClmSksWithPartySk[eachRecord.partySk] || [];
      medClmSksWithPartySk[eachRecord.partySk].push(eachRecord.medClmSk);
    }
    return medClmSksWithPartySk
  }
  updateCheckRowsAndDynmcTblCompRecords(records){
    this.checkedRows = this.checkedRows.filter((record)=>{
      if(records.indexOf(record['medClmSk']) == -1){
          return true;
      }else{
        record.isChecked = false;
        return false; 
      }
    })
    this.checkedRows = this.checkedRows;
  }
  
  collapseExpandedMDERecords(){
    this.mdeData.filter(item => item.expanded).forEach(item => { item.expanded = false; this.expandErrorDetails(item, '')});
    this.closedMdeClaims.filter(item => item.expanded).forEach(item => {item.expanded = false; this.expandErrorDetails(item, '')});
   }
   syncDropdownSelection(){
    this.headerComp && this.headerComp.filterSelection && this.headerComp.filterSelection.syncDropdownSelection();
   }
}