
<div id="{{ tabsId }}" class="uitk-c-tabs" [attr.aria-describedby]="tabsId + '_desc'" [ngClass]="{ 'crr-page': isCrrPage }">
    <div class="a11y-assistive-text uitk-u-element--hidden" id="{{ tabsId }}_desc" aria-hidden="false" tabindex="-1">
    </div>
    <ul role="tablist" [attr.aria-label]="ariaLabel ? ariaLabel : undefined" class="uitk-c-tabs__list">
        <li role="presentation" *ngFor="let tab of tabs; let i = index" routerLinkActive [routerLinkActiveOptions]="{ exact: true }" 
            #rla="routerLinkActive" tabindex="-1" [ngClass]="{
                'uitk-c-tabs__list-item--active': rla.isActive || (tab.link ? false : _selectedIndex === i),
                'tk-tpnl-disabled': tab.isDisabled
            }" [attr.data-index]="i" class="uitk-c-tabs__list-item tab-inside tab-style uitk-c-tabs__link tab-title" (click)="selectTab(i)">
            
            <span id="{{ tabsId }}_{{ i }}_hidden_span" class="a11y-assistive-text uitk-u-element--hidden" *ngIf="_selectedIndex === i || rla.isActive">Tab
                {{ i + 1 }} of {{ tabs.length }} expanded</span>
            <span *ngIf="tab.link" [routerLink]="tabsrouting ? tab.link : undefined" #tabStrip id="{{ tabsId + '_tab' + i + '_tab' }}"
                role="tab" (click)="selectTab(i)" (keydown)="tabKeydownHandler($event)" [attr.aria-selected]="_selectedIndex === i || rla.isActive"
                [attr.aria-expanded]="_selectedIndex === i || rla.isActive" [attr.aria-disabled]="tab.isDisabled" tabindex="{{ focusIndex === i ? 0 : -1 }}"
                [attr.aria-controls]="_selectedIndex === i ? tabsId + '_tab' + i + '_tabpanel' : undefined">
                {{ tabsrouting ? tab.label : tab.tabTitle }}***
                <ng-container *ngIf="!tab.link">{{ tabsrouting ? tab.label : tab.tabTitle }}****</ng-container>
            </span>
            <!-- Duplicated to fix undefiend routerlink defect -->
            <span class=" " *ngIf="!tab.link" #tabStrip id="{{ tabsId + '_tab' + i + '_tab' }}" role="tab"  (keydown)="tabKeydownHandler($event)"
                [attr.aria-selected]="_selectedIndex === i" [attr.aria-expanded]="_selectedIndex === i" [attr.aria-disabled]="tab.isDisabled"
                tabindex="{{ focusIndex === i ? 0 : -1 }}" [attr.aria-controls]="_selectedIndex === i ? tabsId + '_tab' + i + '_tabpanel' : undefined">
                <!-- {{ tabsrouting ? tab.label : tab.tabTitle }} -->
                <div class="t-hdr-width">
                    <div class="t-hdr-cls">{{tab.tabTitle}}</div>
                    <div class="tabs-list rcvd-hdr-cls" [ngClass]="{'sm-hdr-msg': tab.headerMsg  ,'sm-hdr-nomsg':tab.headerMsg === '' && tab.tabTitle === 'Summary' , 'crrPage':isCrrPage}">
                    <div class="rcvd-cnt-cls">{{tab.headerText}}</div>

                        <div class="rcvd-txt-cls">{{tab.headerMsg}}</div>
                    </div>
                </div>
            </span>
            <div class="clms-txt-cls">
                {{ !isCrrPage || i > 3 ? tab.brkDownMsg + " Claims" :""}}
            </div>
        
        </li>
    </ul>
    <ng-content *ngIf="!lazyloading && !tabsrouting"></ng-content>
    <div *ngIf="lazyloading" class="uitk-c-tabs__panel uitk-c-panel__content-container">
        <ng-template #host></ng-template>
    </div>
    <div *ngIf="tabsrouting" class="uitk-c-tabs__panel uitk-c-panel__content-container">
        <router-outlet></router-outlet>
    </div>
</div>