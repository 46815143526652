export class MdeUtility {


    public static getColumns(isFileLvlErrors) {
        return [

            { label: '', sortable: false, id: 'expandKey', dataType: 'text', enableFiltering: true, hideAlways:true, hideColumn: true, searchInput: '' },
            { label: 'Can Edit',sortable: false, id: 'correctionClmGenStatus', dataType: 'text', enableFiltering: true,hideAlways:true, showAlways: false, hideColumn: false, searchInput: '' },
            { label: 'Can Edit',sortable: false, id: 'isEditFlg', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false, searchInput: '' },
            { label: 'Eligible for MDE', sortable: false, id: 'isMDEUIFlg', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false, searchInput: '' },
            { label: 'Claim Type', id: 'clmType', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false, searchInput: '',minWidth:'78px' },
            { label: 'Claim ID',  id: 'clmId', dataType: 'text', enableFiltering: true, showAlways: true, hideColumn: false },
            { label: 'Inbound File Name', id: 'inboundFileName', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: true },
            { label: '837 File Name', id: 'fileName837', dataType: 'text', enableFiltering: true, showAlways: true, hideColumn: false },
            { label: 'Optum Claim ID', id: 'medClmSk', dataType: 'Number', enableFiltering: true, showAlways: false, hideColumn: true },
            { label: 'From Date', id: 'dosFrom', dataType: 'date', enableFiltering: true, showAlways: false, hideColumn: false ,minWidth:'113px'},
            { label: 'Through Date', id: 'dosThrough', dataType: 'date', enableFiltering: true, showAlways: false, hideColumn: false },
            { label: 'Member Name', id: 'memberName', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false },
            { label: 'MBI', id: 'mbi', enableFiltering: true, dataType: 'text', showAlways: false, hideColumn: false },
            { label: 'Provider Name',id: 'billingPrvName', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false },
            { label: 'NPI', id: 'billingPrvNpi', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false },
            { label: 'Error Type', id: 'errType', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false, searchInput: '' },
            { label: 'Error Code', id: 'errCode', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false},
            { label: 'Error Category', id: 'errCategory', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false, searchInput: '' },
            { label: 'Loop', id: 'loop', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false },
            { label: 'Segment', id: 'segment', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: false },
            { label: 'Error Description', id: 'errDescription', dataType: 'date', enableFiltering: false, showAlways: false, hideColumn: false},
            //{ label: 'File Name', id: 'fileName', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: true,hideAlways:isFileLvlErrors },
            { label: 'File Key', id: 'fileKey', dataType: 'Number', enableFiltering: true, showAlways: false, hideAlways: true, hideColumn: true },
            { label: 'File Received Date', id: 'fileReceivedDate', dataType: 'text', enableFiltering: true, showAlways: false, hideColumn: true,hideAlways:isFileLvlErrors }
        ];
    }
    public static getSortorderList(){
        const DATE = "date";
        const TEXT = "text";
        return  {
            "10":{
                columnId : "clmId",
                sortOrder:1,
                dataType : TEXT
            },
            "11":{
                columnId : "clmId",
                sortOrder:-1,
                dataType : TEXT
            },
            "12":{
                columnId : "dosThrough",
                sortOrder:-1,
                dataType : DATE
            },
            "13":{
                columnId : "dosThrough",
                sortOrder:1,
                dataType : DATE
            }
        }
    }

    public static getFilterConfig(isFileLvlErrors?) {
        const apiInputDependantField = {
            model: "viewByDate",
            valueFieldKey: "id"
        };
        const fileNameObj = {
            label: "Inbound File Name",
            id: "inboundFileName",
            model: "inboundFileName",
            name: "inboundFileName",
            type: "textfield",
            columnKey: "CLIENT_FILE_NAME",
            showLabel: true,
            apiInputDependantField
        };
        const filterConfig = MdeUtility.configObj();
        if (!isFileLvlErrors) {
            filterConfig.fields[3].push(fileNameObj)
        }
        return filterConfig;
    }


    public static configObj() {
        const ERROR_CATEGORY = "ERROR_CATEGORY";
        const LOOP_NAME = "LOOP_NAME";
        const FIELD_NAME = "FIELD_NAME";
        const apiInputDependantField= {
            model:"viewByDate",
            valueFieldKey: "id"
        };
        const fileRecievedViewingById = "0";
        const claimDOSViewingById = "1";
        const columnTableInfoMapObj = {
            [fileRecievedViewingById] : "6",
            [claimDOSViewingById] : "7"
        };
        return {
            "requiredFields":[
                {
                    label: "Client Name",
                    id: "clientNames",
                    list: "clientNames",
                    model: "selectedClient",
                    name: "clientName",
                    type: "select",
                    columnKey:"partySK"
                },
                {
                    label: "Viewing By",
                    id: "viewByDate",
                    list: "viewByDates",
                    model: "viewByDate",
                    name: "viewingBy",
                    type: "select",
                    columnKey:"clmDtCriteriaId",
                    valueFieldKey:"id",
                    dependentFields:["timePeriods"]
                },
                {
                    label: "Time Period",
                    id: "timePeriods",
                    list: "timePeriods",
                    model: "timePeriod",
                    name: "timePeriod",
                    type: "datefield",
                    columnKey: "timeCriteriaId",
                    valueFieldKey:"id"
                },
                {
                    label: "Sort Results By",
                    id: "sortby",
                    list: "sortbylist",
                    model: "sortOrder",
                    name: "sortResultsBy",
                    type: "select",
                    isSortByField:true
                }
            ],
            "fields": [
                [{
                    label: "Can Edit",
                    id: "canEdit",
                    list: "canEdits",
                    model: "canEdit",
                    name: "canEdit",
                    type: "select",
                    columnKey:"IS_EDITABLE",
                    apiInputDependantField
                },
                    {
                    label: "Eligible for MDE",
                    id: "mdeFilter",
                    list: "mdeFilters",
                    model: "mdeFilter",
                    name: "eligibleForMDE",
                    type: "select",
                    columnKey:"MDE",
                    apiInputDependantField
                },
                {
                    label: "Claim Type",
                    id: "ClaimType",
                    list: "claimTypes",
                    model: "claimType",
                    name: "claimType",
                    type: "select",
                    columnKey:"MED_CLM_TYP_FLG",
                    postfix:"claims",
                    apiInputDependantField
                },
                {
                    label: "Claim ID",
                    id: "ClaimId",
                    model: "claimId",
                    name: "claimID",
                    type: "textfield",
                    columnKey:"CLAIM_ID",
                    showLabel:true,
                    apiInputDependantField
                },{
                    label: "837 File Name",
                    id: "fileName837",
                    model: "fileName837",
                    name: "fileName837",
                    type: "textfield",
                    columnKey: "FILE_NAME_837",
                    showLabel: true,
                    apiInputDependantField
                }
                ],
                [
                    {
                        label: "Error Type",
                        id: "errorType",
                        list: "errorTypes",
                        model: "errorType",
                        name: "errorType",
                        type: "select",
                        columnKey:"RESPONSE_CATEGORY",
                        postfix:"Errors",
                        apiInputDependantField

                    },
                    {
                        label: "Error Code",
                        id: "errorCode",
                        model: "errorCode",
                        name: "errorCode",
                        type: "textfield",
                        columnKey:"ERROR_CODE",
                        showLabel:true,
                        apiInputDependantField
                    },
                    {
                        label: "Error Category",
                        id: "errorCategory",
                        list: "errorCategories",
                        model: "errorCategory",
                        name: "errorCategory",
                        type: "select",
                        columnKey: ERROR_CATEGORY,
                        postfix:"Errors",
                        apiInputDependantField
                    },
                    {
                        label: "Loop",
                        id: "loop",
                        model: "loop",
                        name: "loop",
                        type: "textfield",
                        columnKey: LOOP_NAME,
                        showLabel:true,
                        apiInputDependantField
                    },
                    {
                        label: "Segment",
                        id: "segment",
                        model: "segment",
                        name: "segment",
                        type: "textfield",
                        columnKey: FIELD_NAME,
                        showLabel:true,
                        apiInputDependantField
                    },
                ],
                [
                    {
                        label: "Member First  Name",
                        id: "memberFirstName",
                        model: "memberFirstName",
                        name: "memberFirstName",
                        type: "textfield",
                        columnKey: "MBR_FST_NM",
                        showLabel:true,
                        apiInputDependantField
                    },
                    {
                        label: "Member Last Name",
                        id: "memberLastName",
                        model: "memberLastName",
                        name: "memberLastName",
                        type: "textfield",
                        columnKey: "MBR_LST_NM",
                        showLabel:true,
                        apiInputDependantField
                    },
                    {
                        label: "MBI",
                        id: "mbi",
                        model: "mbi",
                        name: "mbi",
                        type: "textfield",
                        columnKey: "MBR_ID",
                        showLabel:true,
                        operatorType : "select",
                        apiInputDependantField

                    },
                    {
                        label: "Date of Service",
                        id: "dateOfService",
                        model: "dateOfService",
                        name: "dateOfService",
                        type: "datefield",
                        list: "dateOfServices",
                        columnKey: "SVC_END_DT",
                        valueFieldKey:"id",
                        showLabel:true,
                        apiInputDependantField
            
                    },
                ],
                [
                    {
                        label: "Provider First Name",
                        id: "providerFirstName",
                        model: "providerFirstName",
                        name: "providerFirstName",
                        type: "textfield",
                        columnKey: "BILL_PROV_FST_NM",
                        showLabel:true,
                        apiInputDependantField
            
                    },
                    {
                        label: "Provider Last Name/Organization",
                        id: "providerLastName",
                        model: "providerLastName",
                        name: "providerLastName/Organization",
                        type: "textfield",
                        columnKey: "BILL_PROV_LST_NM",
                        showLabel:true,
                        apiInputDependantField
            
                    },
                    {
                        label: "NPI",
                        id: "npi",
                        model: "npi",
                        name: "npi",
                        type: "textfield",
                        columnKey: "BILL_PROV_NPI_NUM",
                        showLabel:true,
                        operatorType : "select",
                        apiInputDependantField
                    },
                ]
            ],
            "columnTableMapping": 
            {
                IS_EDITABLE:columnTableInfoMapObj,
                MDE:columnTableInfoMapObj,
                MED_CLM_TYP_FLG:columnTableInfoMapObj,
                CLAIM_ID: columnTableInfoMapObj,
                RESPONSE_CATEGORY: columnTableInfoMapObj,
                ERROR_CODE: columnTableInfoMapObj,
                [ERROR_CATEGORY]:columnTableInfoMapObj,
                [LOOP_NAME]:columnTableInfoMapObj,
                [FIELD_NAME]:columnTableInfoMapObj,
                MBR_FST_NM:columnTableInfoMapObj,
                MBR_LST_NM:columnTableInfoMapObj,
                MBR_ID:columnTableInfoMapObj,
                SVC_END_DT:columnTableInfoMapObj,
                BILL_PROV_FST_NM:columnTableInfoMapObj,
                BILL_PROV_LST_NM:columnTableInfoMapObj,
                BILL_PROV_NPI_NUM:columnTableInfoMapObj,
                CLIENT_FILE_NAME:columnTableInfoMapObj,
                FILE_NAME_837:columnTableInfoMapObj
            },
            
        };
    }
}