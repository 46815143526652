import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { OAuthGuard } from './services/auth/oauth.guard';
import { PrivacyPolicyComponent } from './terms-privacy-policy/privacy-policy/privacy-policy.component';
import { TermsOfUseComponent } from './terms-privacy-policy/terms-of-use/terms-of-use.component';
import { SessionTimeoutComponent } from './logout/session-timeout/session-timeout.component';
import { UnauthorizedUserComponent } from './logout/unauthorized-user/unauthorized-user.component';


const appRoutes: Routes = [
  { path: 'home', component: HomeComponent, canActivate: [OAuthGuard]  },
  { path: 'claim/:sourceScreen', loadChildren:()=>import('./claim-errors/claim-errors.module').then(m => m.ClaimErrorsModule),canActivate: [OAuthGuard] },
  { path: 'file/errors/:sourceScreen', loadChildren:()=>import('./claim-errors/claim-errors.module').then(m => m.ClaimErrorsModule),canActivate: [OAuthGuard] },
  { path: 'error/type', loadChildren: () => import('./error-by-type/error-by-type.module').then(m => m.ErrorByTypeModule),canActivate: [OAuthGuard] },
  { path: 'privacy', component: PrivacyPolicyComponent },
  { path: 'terms', component: TermsOfUseComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'session-timeout', component: SessionTimeoutComponent, canActivate: [OAuthGuard]},
  { path: 'closed-claims', loadChildren: () => import('./closed-claim/closed-claim.module').then(m => m.ClosedClaimModule),canActivate: [OAuthGuard] },
  { path: 'reports/fileload', loadChildren: () => import('./file-load/file-load.module').then(m => m.FileLoadModule),canActivate: [OAuthGuard] },
  { path: 'maintenance',loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),canActivate: [OAuthGuard]},
  { path: 'unauthorized-user',component: UnauthorizedUserComponent},
  { path: 'duplicate-claims', canActivate: [OAuthGuard] ,loadChildren: () => import('./duplicate-claims/duplicate-claims.module').then(m => m.DuplicateClaimsModule) },
  { path: 'inprogress-claims', loadChildren: () => import('./inprogress-claim/inprogress-claim.module').then(m => m.InprogressClaimModule),canActivate: [OAuthGuard] },
  { path: 'cms-accepted', loadChildren: () => import('./cms-accepted/cms-accepted.module').then(m => m.CMSAcceptedModule),canActivate: [OAuthGuard] },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(appRoutes)],
})
export class AppRouting { }
