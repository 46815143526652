export class ClaimDetails {
    partySk:string;
    medClmSk:string;
    clmType:string;
    clmId:string;
    dosFrom:Date;
    dosThrough:Date;
    mbi:string;
    billingPrvdrNpi:string;
    mbrFName:string;
    mbrMName:string;
    mbrLName:string;
    billingPrvdrFName:string;
    billingPrvdrMName:string;
    billingPrvdrLName:string;
    isMDEFlg:string;
    errorType:string;
    errorCode:string;
    errorCategory: string;
    loopName: string;
    segment: string;    
    mbrName: string;
    billPrvdrName: string;
    fileKey:string;
    fileName:string;
    fileReceivedDate:string;
    isInbndClaimErrFlag:boolean;
    isCMSClaimErrFlag:boolean;
    isErrByTypeflag :boolean;
    isInProgressClaims :boolean;
    isCMSAcceptedFlag :boolean;
}