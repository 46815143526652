import { Component, Input } from '@angular/core';
import { IUITKAlert, UITKAlertService } from '@uitk/angular';
import { DownloadService } from '../services/download.service';
import { SummaryService } from '../services/tabs/summary.service';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.css']
})
export class DownloadComponent {
  @Input() manageDownloadModal;
  @Input() downloadFilesList;
  private readonly downloadText = "Download";
  private readonly downloadingText = "Downloading...";
  private readonly completeText = "Complete";
  public isLoading:boolean=false;
  deleteConfirmModal = {
    show: false,
    deletedFileIndex:'',
    deletedItem:''
  };
alertConfig: IUITKAlert = {
  id: 'success-alert',
  variant: 'success',
  content: 'Export Successfully Deleted',
  duration: 3000,

};
  constructor(private readonly downloadService: DownloadService, private readonly summaryService: SummaryService,protected alertService: UITKAlertService) {
  }

  
  downloadClick(item) {
    item['downloadStatus'] = this.downloadingText;
    this.downloadService.downloadReport(item).subscribe(response => {
      let myBlob;
      let fileName = item.fileName;
      if (fileName.endsWith('.zip')) {
        myBlob = new Blob([response.body], { type: 'application/zip' });
       // fileName = item.fileName.replace('.csv', '.zip');
      } else {
        myBlob = new Blob([response.body], { type: 'application/vnd.oasis.opendocument.spreadsheet' });
       // fileName = item.fileName;
      }

      const downloadUrl = URL.createObjectURL(myBlob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = fileName;
      a.click();
      item['downloadStatus'] = this.completeText;
      setTimeout(() => {
        URL.revokeObjectURL(downloadUrl);
      }, 100);
      setTimeout(() => {
        this.removeRecord(item);
      }, 3000)
    }, error => {
      delete item.downloadStatus;
      console.log(error);
    });
  }

  removeRecord(item){
    const index = this.downloadFilesList.findIndex((listItem) => listItem.fileName === item.fileName);
    this.downloadFilesList.splice(index, 1);
  }

  confirmDelete() {
    const item:any = this.deleteConfirmModal.deletedItem;
    this.isLoading=true;
    this.downloadService.deleteFile(item).subscribe(data => {
      this.isLoading=false;
      this.deleteConfirmModal.show = false; 
      this.removeRecord(item);
      this.manageDownloadModal.show = true;
      this.showNotification();
      
    });
  }

  deleteFile(index, item) {
    this.manageDownloadModal.show = false;
    this.deleteConfirmModal.show = true;
    this.deleteConfirmModal.deletedFileIndex = index;
    this.deleteConfirmModal.deletedItem = item;
  }
  closeDeletePopup() {
    this.deleteConfirmModal.show = false;
    this.manageDownloadModal.show = true;
  }
  

  closeDownloadPopup() {
    this.manageDownloadModal.show = false;
  }

  
  showNotification() {
    this.alertService.position = 'top-right';
    this.alertService.showNotification(this.alertConfig);
}

  bytesToString(byteCount1: string): string {
    byteCount1 = byteCount1.replace(/,/g, '');
    let byteCount = parseFloat(byteCount1);
    let suf = ["B", "KB", "MB", "GB", "TB", "PB", "EB"];
    if (byteCount1 == '0' || byteCount1 === "")
      return "0" + suf[0];
    if (byteCount == 0 || byteCount == null)
        return "0" + suf[0];
    let bytes = Math.abs(byteCount);
    let place = Math.floor(Math.log(bytes) / Math.log(1024));
    let num = Math.round(bytes / Math.pow(1024, place) * 10) / 10;
    return (Math.sign(byteCount) * num).toString() + suf[place];
  }
}

