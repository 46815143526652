<app-pageloader [pageloaderConfig]="pageloaderConfig"></app-pageloader>
<div *ngIf="pageloaderConfig.show" class="empty-cnt"></div>
<div *ngIf="!pageloaderConfig.show" class="conainer-fluid home-container" >
    <div>
        <div class="home-heading-wrapper" >
           <h1 class="home-heading"> Home Dashboard</h1>
           <div [ngClass]="{'vissibility-hidden' : recordType?.id === 1}" class="days-left-button-wrapper">
            <div class="claim-status-text" tabindex="0" [ngStyle]="{'background-color':getColor()}">
                <span *ngIf="numDays != 1"> <strong>{{numDays}}</strong> Days Since Last File
                    Submitted </span>
                <span *ngIf="numDays == 1"> <strong>{{numDays}}</strong> Day Since Last File
                    Submitted </span>
            </div>
        </div>
        </div>
        <form #myMultiSelectForm="ngForm">
        <div class="drop-downs-grp margin-top">
            <div class="client-name">
                <label uitkLabel>Client Name</label>
              
                <uitk-select
                                id="clientName"
                                name="clientName"
                                [itemList]="clientNames"
                                
                                [defaultLabelFlag]="false"
                                [(ngModel)]="clientName"
                                (onDropdownChange)="changeClient($event?.value)"
                            >
                            </uitk-select>
                            
            </div>
            <div *ngIf="asmEnabled === 'Y' " class="Viewing-by">
                <label for="recordType" uitkLabel>Record Type</label>
               
                <uitk-select
                id="recordType"
                name="recordType"
                [itemList]="recordTypes"
                [disabled]="isInvalidValidClient()"
                [defaultLabelFlag]="false"
                [(ngModel)]="recordType"
                (onDropdownChange)="changeRecordType($event?.value)"
            >
</uitk-select>
            </div>
            <div class="Viewing-by">
                
                <label for="viewBy" uitkLabel>Viewing By</label>
                <uitk-select
                                id="viewBy"
                                name="viewBy"
                                [itemList]="viewByDates"
                                [disabled]="isInvalidValidClient() || recordType?.id === 1"
                                [defaultLabelFlag]="false"
                                [(ngModel)]="viewByDate"
                                (onDropdownChange)="changeViewingBy($event?.value)"
                            >
                </uitk-select>
            </div>

            <div class="time-period">
                <label for="timePeriods" uitkLabel>Time Period</label>
                
                <uitk-select
                [disabled]="isInvalidValidClient()"
                id="timePeriods"
                name="timePeriods"
                [itemList]="timePeriods"
                
                [defaultLabelFlag]="false"
                [(ngModel)]="timePeriod"
                (onDropdownChange)="changeTimePeriod($event?.value)"
            >
            </uitk-select>
            </div>

            <div class="custom-date" *ngIf="isCustomDateRangeSelection()">
                <div *ngIf="customDateInvalid && isApplyClicked" class="invalid-field-msg">
                    <svg xmlns="http://www.w3.org/2000/svg" class="alert-icon" viewBox="0 0 17.001 17.001">
                        <path id="Exclusion_1" data-name="Exclusion 1"
                            d="M-5775.5,9094a8.51,8.51,0,0,1-8.5-8.5,8.511,8.511,0,0,1,8.5-8.5,8.51,8.51,0,0,1,8.5,8.5A8.509,8.509,0,0,1-5775.5,9094Zm0-5a1.5,1.5,0,0,0-1.5,1.5,1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5A1.5,1.5,0,0,0-5775.5,9089Zm0-10a1.5,1.5,0,0,0-1.5,1.5v6a1.5,1.5,0,0,0,1.5,1.5,1.5,1.5,0,0,0,1.5-1.5v-6A1.5,1.5,0,0,0-5775.5,9079Z"
                            transform="translate(5784.001 -9076.999)" fill="#fff" />
                    </svg>
                    <span class="invalid-date-range-text">     Date Range is invalid</span>
                </div>
                    <div class="date-input" [ngClass]="{'date-invalid' : fromDateInvalid && isApplyClicked }">
                        <label>From </label>
                        <div class="from-info">
                            <svg xmlns="http://www.w3.org/2000/svg" class="info-icon" viewBox="0 0 16 15.999">
                                <path id="Union_3" data-name="Union 3"
                                    d="M-5784,9085a8,8,0,0,1,8-8,8,8,0,0,1,8,8,8,8,0,0,1-8,8A8,8,0,0,1-5784,9085Zm.94,0a7.068,7.068,0,0,0,7.058,7.061,7.066,7.066,0,0,0,7.059-7.061,7.067,7.067,0,0,0-7.059-7.059A7.068,7.068,0,0,0-5783.059,9085Zm6.237,4.592v-6.224h1.646v6.224Zm0-7.067V9081h1.646v1.522Z"
                                    transform="translate(5784 -9077.001)" fill="#c25608" />
                            </svg>
                            <span class="tooltiptext">A Custom Date Range must be within the open CMS submission period and cannot exceed a 13 month time span.</span>
                        </div>
                        <!-- <uitk-calendar tkId="timePeriodFrom" [(ngModel)]="customFromDate" [model]="dateConfig" (ngModelChange)="onDateChanged(customFromDate,customThroughDate)"> 
                        </uitk-calendar>-->
                        <uitk-date-picker
                            uitkId="timePeriodFrom"
                            name="selectedDateDefault"
                            [(ngModel)]="customFromDate"
                            (ngModelChange)="onDateChanged(customFromDate,customThroughDate)"
                        ></uitk-date-picker>
                    </div>
                    <div class="date-input through-date"
                        [ngClass]="{'date-invalid' : throughDateInvalid && isApplyClicked }">
                        <label>Through </label>
                      
                        <uitk-date-picker
                            uitkId="timePeriodThrough"
                            name="timePeriodThrough"
                            [(ngModel)]="customThroughDate"
                            (ngModelChange)="onDateChanged(customFromDate,customThroughDate)"
                        ></uitk-date-picker>
                    </div>
            </div>
            
                <div *ngIf="isCustomDateRangeSelection()" class="apply-btn-wrapper">
                    <button 
                    class="uitk-c-button uitk-c-button--default uitk-u-margin-right--base uitk-u-margin-bottom--xs" 
                    (click)="onApplyBtnClick()" type="submit"
                        id="apply">Apply</button>
                </div>  
        </div>
        </form>
        <div>
            <home-tabs-component #homeTabs [tabsContent]="tabsData" (onSelectTab)="onSwitchTab($event)" [tabsLazy]="tabs" [lazyloading]="true" [isCrrPage]="recordType?.id === 1"></home-tabs-component>
        </div>
    </div>
</div>
<uitk-dialog [(showModal)]="userInactiveDialogModal.show" 
 uitkId='userInactive'
    triggerElementId='userInactive_openBtn4'>
    <uitk-dialog-content>
        <div cdkTrapFocus="true">
            <p>
                    You are not an authorized user, Please contact portal Administrator.
            </p>
        </div>
    </uitk-dialog-content>
</uitk-dialog>