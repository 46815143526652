<div class="row margin-top-11">
    <div class="col-md-4 padding-left-3 width-left-3">
        <div><span class="chart-title">CMS Accepted Claim Types</span><span class="margin-left-15"><a [routerLink]="['/cms-accepted']">View Details</a></span></div>
    </div>
    <div class="col-md-8 cms-check-box-wrapper">
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_1"  [(ngModel)]="isInstitutional" name="chcbx_1" class="custom-control-input" (change)="reloadGraph('Institutional')" checked="checked">
            <label class="custom-control-label red" for="chcbx_1">Institutional</label>
        </div>
        <div class="custom-control custom-checkbox custom-control-inline">
            <input type="checkbox" id="chcbx_2"  [(ngModel)]="isProfessional" name="chcbx_2" class="custom-control-input" (change)="reloadGraph('Professional')">
            <label class="custom-control-label red" for="chcbx_2">Professional</label>
        </div>
    </div>
</div>
<div class="margin-bottom-35 graph-wrapper">
    <line-chart [hidden]="isBarChartVissible()" [isHidden]="isBarChartVissible()"></line-chart>
    <app-bar-charts [scopeData]="data" [hidden]="!isBarChartVissible()" [isHidden]="!isBarChartVissible()"></app-bar-charts>
</div>
<app-graph-switch-frequency [scopeProps]="data"></app-graph-switch-frequency>
<div class="row">
    <div class="col-md-3 padding-left-3" *ngFor="let tab of tabs">
        <div><span>{{tab.tabTitle}}</span></div>
        <div class="count-title"> <span>{{tab.headerText}}</span></div>
        <div class="border-top-title"></div>
        <div><div class="clms-txt-cls">{{tab.brkDownMsg}} Claims</div></div>
    </div>
</div>